.userModalInfo {
    flex-direction: column;
    margin: 10px 0;
    justify-content: start;
    /* align-items: end; */
}

.userInfoTxt {
    font-size: 20px;
    margin: 5px 0;
}

.userModal {
    align-items: end;
    justify-content: space-between;
    height: fit-content;
    margin: 0 30px;
}

.userModalSave {
    flex-direction: column;
    justify-content: start;
    height: 100%;

}

.userInfo {
    font-size: 14px;
    font-weight: 500;
    margin: 3px 0 !important;
}

.trinti {
    /* color: black!important; */
    text-transform: capitalize !important;
}

.trinti:hover {
    background-color: transparent;
    color: #473e9d;
}


.yellow {
    color: #DE9404;
    font-weight: 700;

}

.green {
    color: green;
    font-weight: 700;
}

.red {
    color: #E55539;
    font-weight: 700;
}

.telEmail {
    color: black;
    font-weight: 400;
}

@media (max-width: 767px) {
    .userAccSummary{
        margin: unset;
    }
    .Vartotojai .Accordion {
        flex-direction: row !important;
    }

    .userAccSummary {
        width: 100%;
        align-items: flex-start !important;
        margin: unset !important;
    }

    .userInfoAcc>div {
        flex-direction: column-reverse !important;
        align-items: flex-start !important;
    }

    .userInfoAcc>div p {
        margin: unset !important;
    }

    .userModal {
        margin: unset;
        flex-direction: column;
        align-items: flex-start;
    }

    .userModalInfo {
        margin: 10px 5px;
    }

    .userSelectSubscription {
        margin: 10px 5px !important;
    }

}