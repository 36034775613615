.txtField {
    width: 100% !important;
    display: flex !important;
}

.txtFielfContainer {
    display: flex !important;
}

.txtFieldContainer {
    margin: 20px 0;
}

.txtFieldForm {
    width: 100%;
}

.biggerTxtMargin {
    margin: 5px 0 !important;
    width: 100%;
}

.errorLabel {
    color: red !important;
}
.MuiFormHelperText-root {
    color: red;
    }

.txtfieldLabelInput {
    transform: translate(14px, 9px) scale(1) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused.txtfieldLabelInput {
    transform: translate(14px, -9px) scale(0.75) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.txtfieldLabelInput{
    transform: translate(14px, -9px) scale(0.75) !important;
}

@media screen and (max-width: 767px) {

    .txtFielfContainer {
        width: 100%;
        margin: 30px 0 !important;
    }

    .txtFieldContainer {
        width: 100%;
    }

    .txtFieldContainer {
        margin: 20px 0 4px 0;

    }

}