.rezTenName {
  font-size: 24px;
  font-weight: 700;
  font-family: Poppins;
  margin: unset;
  border-bottom: 1px solid #8080806b;
  padding: 0 0 10px 0;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-clickable.MuiChip-clickableColorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-outlinedDefault {
  background-color: var(--third-background) !important;
  color: var(--main-color-ours);
  border: none !important;
  border-radius: 4px !important;
}


.rezultatasSkaicius {
  font-weight: 700;
}

.question .title {
  min-height: 40px;
}

.title {
  align-items: center;
}

.anwsers {
  font-weight: 700;
}

.question .anwsers {
  background-color: unset;
}

.balas {
  width: 100% !important;
  margin: 3px !important;
}

.Balas{
  align-items: center;
}

.alignTop{
  align-items: flex-start;
}
.atsakmyasRezultatas {
  background-color: white !important;
  padding: 0px 25px !important;
  border-radius: 4px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.pageContent>div {
  margin: 8px 0;
}

.neraAts {
  font-size: 18px;
  font-weight: 500;
  color: var(--main-color-ours);
}

.tendencijosChip {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.chip {
  margin: 3px !important;
}

.darbuotjo,
.imones,
.bendras {
  min-height: 40px;
  background: white;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  color: var(--main-color-ours);
  font-weight: 700;
}

.metai2 {
  min-height: 40px;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.benrasBalaiCont {
  display: flex;
  flex: 1;
}

.title {
  align-items: center;
  display: flex;
}

.names.column.question {
  min-width: 500px;
}

.mobBendras {
  display: none;
}

.bendrasBalai {
  margin: 0 5px;
}

.filterDrawer {
  position: relative;
}

.closeIconFiltras {
  color: var(--main-color-ours);
  position: absolute;
  right: 20px;
  top: 40px;
  cursor: pointer;
}

.expandIconRez {
  color: var(--main-color-ours) !important;
}

.rezBotSpace {
  padding-bottom: 60px;
}

.RezultataiTable .pageContent>div {
  display: flex;
  margin: unset !important;
  padding: unset !important;
}

.RezultataiTable .pageName>div {
  padding: 20px 0px !important;
}

.RezultataiTable .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.RezultataiTable .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.stickyParent {
  position: relative;
  min-height: 100vh;
  width: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.relative {
  position: relative !important;
}

.standartPadding.sticky {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.downloadBtn span {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.downloadBtn{
  min-width: unset!important;
  margin: 0 5px!important;
}

.downloadBtn:hover{
  background-color: rgba(18, 10, 103, 0.04);
}

/* Tooltip container */
.tooltip {
  position: relative;
  /* background-color: unset!important; */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #212738;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: fit-content;
  bottom: 110%;
  left: 50%;
  margin-left: -55%;
  /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.Mui-checked {
  color: var(--main-color-ours) !important;
}

.borderBot {
  border-bottom: 0.5px solid #9289e6 !important;
}

.hidden {
  visibility: hidden;
}

.sa-table__btn {
  margin-left: unset !important;
  margin: 10px 5px !important;
}

.download {
  margin: 9px 5px !important;
}

.noSoStandartPadding {
  padding: 0 0 0 0;
}

.verticalCenter.flex.row.fullWidthMob.spaceBetween {
  flex: auto;
}

.rezRodoma {
  margin: 2px!important ;
}

.rezRodomaAuto{
  margin: 10px 1px!important;
}

@media (max-width: 1440px) {

  .verticalCenter.flex.row.fullWidthMob.spaceBetween {
    justify-content: flex-start !important;
  }

  .jpg {
    margin-right: 20px !important;
  }
}

@media (max-width: 767px) {
  .pdfIhtmlButton.singleQuestion {
    margin-left: unset;
  }

  .question .accTxt{
    max-width: unset!important;
  }
  .rezRodoma {
    margin: 2px 0!important ;
  }
  
  .rezRodomaAuto{
    margin: 2px 0!important;
  }
  .rezRodomaMob{
    margin: 0!important;
  }
  .fullWidthRezTend{
    width: 100%;
  }

  .mobileRodFiltr{
    flex-direction:row!important;
    margin-bottom: 10px;
  }

  .mobRezAts {
    background-color: white;
    padding: 0 10px;
    border-radius: 6px;
  }

  .routaiCont .rezAccSummVid {
    margin: unset !important;
  }

  .question .MuiGrid-root {
    flex-direction: column-reverse;
    display: flex;
  }

  .MuiBox-root.css-8msxzc {
    padding-left: unset !important;
  }

  .MuiGrid-root.MuiGrid-item {
    max-width: 100% !important;
  }

  .fullWidthMob {
    width: 100%;
  }

  .MobHidden {
    display: none !important;
  }

  .RezultataiTable .MuiAccordionDetails-root.AccordionDetalilsCont {
    padding: 20px !important;
    background-color: var(--secondary-background) !important;
  }

  .rezultataiBalasTxt {
    margin-right: 10px !important;
  }

  .rezultataiAts {
    background-color: unset !important;
    color: var(--main-color-ours) !important;
    padding: 10px 0 !important;
    cursor: pointer !important;
  }

  .width100Mob {
    width: 100%;
  }

  .accTxt {
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: 500;
  }

  .MuiAccordionDetails-root.AccordionDetalilsCont {
    padding: 7px !important;
  }

  .rezAccSummVid.accordionSummary {
    align-items: flex-start;
  }

  .routaiCont .rezAccSumm {
    flex-direction: row !important;
  }

  .names.column.question {
    display: none;
  }

  .darbuotjo,
  .imones,
  .bendras,
  .metai2 {
    padding: 10px 0;
  }

  .benrasBalaiCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 10px;
  }

  .mobBendras {
    display: flex;
    width: 100%;
    margin: 10px 0;
  }

  .tendencijosSelect {
    width: 100%;
  }

  .pageContent>div {
    grid-gap: unset;
  }

  .atsakmyasRezultatas {
    padding: unset !important;
    min-height: 50px;
    width: 100% !important;
    margin-top: 10px !important;
  }

  .balas {
    min-height: 60px !important;
    justify-content: space-between;
    padding: unset !important;
  }

  .filtrasBtn {
    width: unset !important;
    /* margin: 5px !important; */
    flex: 1;
  }
  .rezRodomaMob {
    width: 100% !important;
  }

  .rezRodoma {
    width: 100% !important;
  }

  .rezRodomaAuto{
    width: 100% !important;
    /* margin: 8px 0!important; */
  }

  .question .anwsers {
    padding: unset;
    margin: unset;
    justify-content: space-between;
  }

  .question .title {
    padding: unset;
    min-height: unset;
    background-color: unset;
  }

  .question .anwsers>div>div:not(.mobile) {
    padding: unset;
  }

  .metai {
    display: none;
  }
}