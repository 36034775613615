.politikaCont {
  padding: 60px;
}

.opener::after {
  content: url("drop1.png");
  padding: 14px;
}

.opener.open::after {
  content: url("drop2.png");
  padding: 14px;
}

.hidden {
  display: none;
}

.routaiCont table.policiesTable>tbody>tr>td:first-child {
  line-height: unset !important;
  font-size: unset !important;
  width: unset !important;
}

.routaiCont table.policiesTable>tbody>tr>td {
  padding: 5px !important;
  border: 0.5px solid black;
}

.routaiCont table.policiesTable>tbody{
  border: 1px solid black;
}
