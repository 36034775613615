.fakeModal {
  height: fit-content;
  /* width: 25%; */
  background-color: #ffffff70;
  padding: 50px 120px;
  box-shadow: 0px 10px 98px rgba(10, 8, 106, 0.15);
  border-radius: 6px;
}

.profilisCont {
  background-color: var(--third-background);
  /* height: 87.5vh; */
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilioImonesMargin {
  margin: 20px 0;
}

@media screen and (max-width: 1225px) {
  .fakeModal {
    width: unset;
    padding: 40px;
  }
}
