.ham {
  display: none;
}

.navContainerMob {
  display: none;
}


.navBar {
  /* position: fixed; */
  top: 0;
  /* width: 100vw; */
  /* background-color: var(--main-color-ours); */
  /* background: var(--main-backgroundDark); */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  overflow: hidden;
  /* border-bottom: 2px solid var(--main-color-ours); */
  /* margin-bottom: 10px; */
}

.navLogo {
  margin: 20px;
  height: 60px;
}

.navLogo img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
  height: 100%;
}

.navContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: -webkit-fill-available;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: flex-start;
  /* max-width: 40%; */
}

.navItem {
  margin: 10px;
  /* color: var(--main-background) */
  color: rgb(39, 39, 39, 0.8);
  text-decoration: none;
  font-weight: 600;
  z-index: 100;
  cursor: pointer;
}

.drawer {
  padding: 0 50px !important;
}

.NavBarDesine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  align-items: center;
  z-index: 0;
}

.navBarDesinesDesine {
  display: flex;
  align-items: center;
}

.profilis {
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.kalba {
  border: none !important;
}


.kalba:focus {
  box-shadow: none;
  outline: none;
}

.kalba.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.kalbaNavMobile{
  display: none!important;
}

@media screen and (max-width: 767px) {

  .kalbaNavMobile{
    display: flex!important;
  }
  .ham {
    display: flex;
  }

  .prisijungimoKalbos {
    right: 0px;
    top: 10px;
  }

  .prisijungimoKalbos .MuiInputBase-input {
    color: white;
  }

  .prisijungimoKalbos svg {
    color: white;
  }

  .mobProfLog {
    align-items: center;
  }

  .navBar {
    justify-content: space-around;
  }

  .kalbaMob.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  /* .navContainerMob {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: var(--main-background);
  position: fixed;
} */
  .navContainerMob {
    display: flex;
  }

  .NavBarDesine {
    display: none;
  }

}