.MuiDataGrid-row.MuiDataGrid-row--lastVisible {
    background-color: var(--secondary-background);
    border-radius: 6px;
}

.copyBtn {
    color: var(--main-color-ours) !important;
    background-color: white !important;
}

.Paviešinta {
    color: green;
    font-weight: 700;
    text-transform: uppercase;
}

.Sustabdyta {
    color: #E55539;
    font-weight: 700;
    text-transform: uppercase;

}

.Nepaviešinta {
    color: #907CFF;
    font-weight: 700;
    text-transform: uppercase;

}

.apklausaName {
    flex-direction: column;
}

.formModal {
    justify-content: space-between;
}

.fakeAccordion {
    width: 100;
    background-color: var(--secondary-background);
    padding: 22px 35px;
    margin: 10px 0;
    justify-content: space-between;
    border-radius: 6px;
}

.ikonosName {
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin: 0 15px;
    font-weight: 700;
    color: var(--main-color-ours);
}

.apklausaSummIkonos {
    margin: 0 20px 0 0;
}


html[native-dark-active] .sd-radio--checked .sd-radio__decorator::after {
    background-color: var(--native-dark-bg-color) !important;
    filter: invert(1);
}

.ApklausuAkord .Accordion {
    flex-direction: row !important;
    border-radius: 6px !important;
}

.ApklausuAkord {
    display: flex;
    flex-direction: column;
}

@media (max-width: 767px) {

    .mobMargin{
        margin: 5px 0!important;
    }

    .ApklausuAkord .Accordion {
        flex-direction: column !important;
        padding: 0px !important;
    }
}