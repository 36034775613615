.CheckLabel{
    font-size: 13px;
    display: flex;
    align-items: center;

}

.checkAlign{
    display:table-cell;
}
.CheckLabel a{
    color: black!important;
}