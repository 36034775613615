.AccordionButtons {
    align-items: center;
    justify-content: space-around;
}

.accordionSummary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin: 0 20px;
}

.Accordion {
    background-color: var(--secondary-background) !important;
    /* padding: 10px 20px!important; */
}

.Vartotojai .Accordion{
    border-radius: 6px!important;
}

.AccordionDetalilsCont {
    background-color: var(--third-background) !important;
}

.accordionContainer {
    padding: 10px 0;
}

.accorcionName {
    font-size: 24px;
    font-weight: 700;
    color: var(--main-color-ours);
    font-family: Poppins;
    margin: unset;
}

.name_action {
    width: 100%;
    justify-content: space-between;
    padding: 0px 0 10px 0;
    }

.accordionButtons {
    display: flex;
    justify-content: space-around;
}


@media screen and (max-width: 767px) {
    .accordionSummary {
        flex-direction: column;
    }

    .accordionButtons{
        flex-direction: column;
    }
    .Accordion{
        flex-direction: column!important;
    }
}
.RezultataiTable .AccordionDetalilsCont {
    background-color: color(srgb 0.9686 0.9686 0.9687)!important;
    }