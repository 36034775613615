.smallMargins > * {
  margin: 5px;
}
.noMargins > * {
  margin: 0;
}
.apklausaInfo {
  display: flex;
}
.standartPaddingPage {
  padding: 10px 20px;
}
.creatormodalTextfields > div {
  margin: 10px 20px;
}
#root,
body {
  background: #d0c2ac;
}
.textMainColor {
  color: var(--main-color-ours);
}
.textSecondaryColor {
  color: var(--secondary-color-ours);
}
.scroller {
  max-height: 420px;
  overflow: auto;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 20px;
}
.row.full-width-row-wrapped > * {
  flex: 1;
  padding: 30px 20px;
  min-width: 220px;
  background: #0000ff0a;
  margin: 4px;
}
.absoluteDesktop {
  position: fixed;
}

.takas a {
  margin: 0 10px;
  color: #c9754f;
}
.takas {
  padding: 20px;
  background: #000000cf;
  color: red;
}

.dataSelector {
  padding: 20px;
}
.dataSelector {
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: max-content;
}
.dataSelector > div > div > button {
  /*! flex: 1; */
  width: 200px;
  margin: 6px;
}
.drawer > .MuiPaper-root.MuiPaper-elevation {
  z-index: 1 !important;
  max-width: 100vw;
}
.drawer > .MuiPaper-root.MuiPaper-elevation {
  background-color: #ffffff24;
}
.patientInfo {
  max-width: 940px;
  margin: 20px auto;
}
.row.toSides {
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.stasisText {
  background: #e8eefe40;
  padding: 10px;
}
.stasisText .txtFieldContainer > * {
  width: 100%;
}
.stasisText .txtFieldContainer {
  width: calc(100% - 30px);
}
.verticalCenter.filtrasBtn.row {
  margin-right: 10px;
}
.verticalCenter.filtrasBtn.row span {
  margin: 0;
}

.fakeModal a span {
  margin: 0;
  padding: 2px;
}
.paddingBtn button {
  margin: 25px 0;
}

.visit {
  margin-top: 25px;
}
.visit .row {
  margin-top: 15px;
}
.visit .row button {
  margin-right: 15px;
}
/* //pdf */
.pdfContainer {
  /* min-height: 70vh; */
}
.pdfContainer iframe {
  width: 100%;
  height: 100%;
  max-width: 98vw;
}
/* ////// animals /////// */
.row.center.full{
  justify-content: space-between;
  padding: 5px 21px;
}
.row.center.full button {
  margin-top: 20px;
}
.modal3 img {
  width: 100vw;
}
.patient.container {
  background: white;
  /*! justify-content: flex-start; */
  align-items: flex-start;
  margin-top: 20px;
}
.profileImgAnimal img {
  max-height: 100px;
  max-width: 100px;
  object-fit: scale-down;
}
.card.row {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.card.row {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.cardColor{
  background-color: #4f151542;
}
.PatientBackgroud{
background-color: rgba(173, 146, 38, 0.16);
padding: 20px;
}
.profileImgAnimal {
  margin-left: 2dvb;
}
.listAccordion{
  background-color: rgba(173, 146, 38, 0.16);
padding: 20px;
}
.animalData p {
  margin: 4px 0;
}
.animalData {
  margin: 10px 0;
}

.animalBtnGoIn {
  margin: 10px 20px;
}
@media screen and (max-width: 768px) {
  .card.row {
    justify-content: center;
  }
}

.barnContainer img {
  height: 37px;
  cursor: pointer;
}
.barnContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.barnContainer p {
  margin: 0;
  font-size: 10px;
  margin-top: -3px;
  font-weight: 600;
  color: var(--secondary-color-ours);
}
.small.patientTextImg {
  width: 200px;
}
