/* Updated */

.sa-question__title {
  display: none;
}

.sticky {
  z-index: 999;
}

.tendencijosBorderBot {
  padding-bottom: 5px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1;
}

.standartPadding.sticky {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.standartPadding.bgBlue.tendRezMargin {
  min-height: 100vh;
  padding-top: 15px;
}

/*Tabulator*/
.sa-table.sa-tabulator {
  overflow: auto;
  white-space: nowrap;
}

.pdfIhtmlButton {
  margin: 0px 0px 30px 0px;
  display: flex;
  float: right;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 17px;
  right: 45px;
}

.RezultataiTable .AccordionDetalilsCont {
  background-color: #f7f7f7 !important;
}

.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-dense.MuiListItemButton-gutters {
  padding: 0px 15px !important;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 30px !important;
}

.filterBtnPadding {
  padding: 10px 0;
}

.filterPadding {
  padding: 0 40px;
}

.sa-table__action-container .sa-table__drag-button use {
  fill: #665ae1 !important;
}

.sa-tabulator .tabulator .tabulator-tableHolder::-webkit-scrollbar-thumb {
  background: #665ae1 !important;
}

.sa-table.sa-tabulator {
  overflow: auto;
  white-space: nowrap;
  font-family: Helvetica, Tahoma, Arial, sans-serif, "PingFang SC" !important;
}

/* .sa-table__global-filter.sa-table__global-filter, .sa-table__filter.sa-table__filter {
                      display: none;
                      }
                      .sa-table__header-extension + .sa-table__header-extension {
                        display: none;
                        }
                        .sa-table__entries-label.sa-table__entries-label--right, .sa-table__entries-label--left {
                          display: none!important;
                          } */
.sa-table select {
  border-radius: 5px !important;
}

.sa-table__btn--gray {
  border-color: #6a5ee2 !important;
  color: #665ae1 !important;
}

.sa-tabulator .tabulator-page {
  color: #665ae1 !important;
}

.nauja_apklausa {
  width: 100%;
  padding: unset !important;
  color: black !important;
  max-width: 210px;
}

.svc-logic-question-value>.sd-question__erbox--outside-question,
.sd-element--with-frame:not(.sd-element--collapsed)>.sd-question__erbox--outside-question {
  margin-left: unset !important;
  margin-right: unset !important;
  /* padding: 10px; */
  /* width: calc(100% + 2 * var(--sd-base-padding)); */
  max-width: 92%;
  margin: 0px;
  padding-bottom: 3px;
  margin-bottom: 10px;
}

.routaiCont .sd-table__cell.sd-matrix__cell.sd-table__cell--row-text {
  line-height: 24px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  width: 63% !important;
}

.noSoStandartPadding .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.apklausaName .margin {
  margin: 5px 0 !important;
}


.question .accTxt {
  max-width: 75%;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 10px;
}

.sticky.standartPadding {
  padding-top: 3px;
}
.tendencijosChip {
  padding-bottom: 10px;
}
.atsakmyasRezultatasPilkas {
  cursor: pointer;
}
.sd-question__erbox--above-question {
  border-radius: unset!important;
}
.sd-title.sd-container-modern__title {
  box-shadow: unset!important;
}
@media (max-width: 957) {
  .mobile .question .accTxt {
    max-width: 200px !important;
    font-weight: 200;
  }
}
.sa-tabulator__downloads-bar .sa-table__btn {
  box-shadow: 1px 1px 5px #e7e7e7;
  }

  
  .sa-tabulator__downloads-bar .sa-table__btn:hover
  {box-shadow:1px 1px 5px lightgrey}

.Accordion {
    cursor: pointer!important;
    }